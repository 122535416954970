/** @jsx jsx */
import PropTypes from 'prop-types';
import { jsx } from 'theme-ui';

const Haskell = ({ color, ...props }) => (
  <svg
    width="1.4em"
    height="1em"
    viewBox="0 0 728.000000 508.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,508.000000) scale(0.100000,-0.100000)"
      fill={color}
      stroke="none"
    >
      <path d="M1342 4340 l-252 -5 595 -893 c327 -491 595 -898 595 -904 0 -5 -266 -409 -591 -896 -325 -487 -593 -892 -596 -899 -4 -11 80 -13 438 -13 244 0 449 4 454 8 13 10 35 40 198 286 77 116 206 309 286 429 80 120 157 236 171 257 28 45 178 269 210 315 12 17 93 139 182 272 l160 242 -592 888 c-325 488 -597 893 -603 900 -15 15 -292 20 -655 13z" />
      <path d="M2544 4340 l-252 -5 599 -898 598 -898 -157 -235 c-86 -129 -173 -258 -192 -288 -53 -81 -66 -100 -472 -709 -208 -312 -378 -569 -378 -572 0 -3 202 -5 450 -5 l450 0 372 560 c205 308 376 560 380 560 4 0 162 -233 352 -517 189 -285 358 -537 375 -560 l31 -43 445 0 c245 0 445 4 445 8 0 8 -47 79 -467 707 -110 165 -588 883 -1063 1595 -474 712 -863 1296 -864 1297 -4 5 -434 7 -652 3z" />
      <path d="M4391 2990 l199 -300 805 0 805 0 0 300 0 300 -1004 0 -1004 0 199 -300z" />
      <path d="M4800 2385 c0 -3 87 -138 194 -299 194 -293 194 -294 233 -297 21 -2 247 -4 503 -3 l465 1 3 301 2 302 -700 0 c-385 0 -700 -2 -700 -5z" />
    </g>
  </svg>
);

export default Haskell;

Haskell.propTypes = {
  color: PropTypes.string,
};

Haskell.defaultProps = {
  color: 'white',
};
